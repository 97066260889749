import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import useBaseUtils from "~/functions/useBaseUtils";
var paypalClientID = process.env.PAYPAL_CLIENT_ID;
export default defineComponent({
  props: {
    description: {
      type: String,
      "default": "Premium TIKR.com membership through" // TODO: going to need to split the type and enddate?
    },
    enddate: {
      type: String,
      "default": "FIXME!" // this should be an internationalized string
    },
    price: {
      type: String,
      "default": "400.00"
    },
    email: {
      type: String,
      required: true
    },
    sub: {
      type: String,
      required: true
    },
    subtype: {
      type: String,
      required: true,
      "default": "premium"
    }
  },
  setup: function setup() {
    var _useBaseUtils = useBaseUtils(),
      I18nFn = _useBaseUtils.I18nFn;
    return {
      I18nFn: I18nFn
    };
  },
  data: function data() {
    return {
      loaded: false,
      error: null
    };
  },
  mounted: function mounted() {
    var script = document.createElement("script"); // One time payment below
    script.src = "https://www.paypal.com/sdk/js?client-id=".concat(paypalClientID); // add a confirmation page (not recommended)
    // script.src = `https://www.paypal.com/sdk/js?client-id=${paypalClientID}&commit=false`
    // use .src url below to remove the confirmation page
    // only use payment vault payment methods (for subscriptions)
    // script.src = `https://www.paypal.com/sdk/js?client-id=${paypalClientID}&vault=true`
    // add attribute below to enable 3D Secure for european markets
    script.setAttribute("data-enable-3ds", "data-enable-3ds");
    script.addEventListener("load", this.setOneTime);
    document.body.appendChild(script);
  },
  methods: {
    setOneTime: function setOneTime() {
      // this.loaded = true
      this.loaded = true;
      document.getElementById("paypal-button-container").innerHTML = "";
      var description = this.description;
      var value = this.price;
      var email = this.email;
      var vue = this; // const sub = this.sub
      /**
             * messing with payer doesn't do much - it becomes
             * modified as the actual person pays
             * add the invite code on the purchase_unit_request?
             */
      window.paypal.Buttons({
        createOrder: function createOrder(data, actions) {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [{
              description: description,
              custom_id: email,
              amount: {
                currency_code: "USD",
                value: value // invoice_id:
                //   "string appears in payers history and emails payer receives"
              }
            }],
            application_context: {
              // brand_name:
              //   "string label overrides businessname in the paypal account on the site",
              shipping_preference: "NO_SHIPPING" // user_action: "PAY_NOW - enum maybe I should do this?"
              // return_url: "string URL where customer is redirected after customer approves payment",
              // cancel_url: "string URL where customer is redirected after customer cancels payment"
            }
          });
        },
        onCancel: function onCancel() {
          // console.log("onCancel Data: ", data)
          // console.log("onCancel actions: ", actions)
          // show a cancelPage or return to cart
          vue.$emit("payment-cancel");
        },
        onError: function onError(err) {
          vue.error = "Payment Error please try again or contact support@tikr.com error message: ".concat(err);
          vue.$emit("payment-error", err);
        },
        onApprove: function onApprove(data, actions) {
          // one time onApprove below
          return actions.order.capture().then(function (details) {
            // console.log("onApprove: ", data)
            // console.log("action.order.capture details", details)
            // alert("Transaction completed by " + details.payer.name.given_name)
            var paymentDetails = {
              captureDetails: details,
              // orderDetails: details,
              onApproveData: data
            };
            vue.$emit("payment-complete", paymentDetails);
          }); // get the order details
          // return actions.order.get().then(function(orderDetails) {
          //   // show a confirmation using the details from orderDetails
          //   // then listen for a lick on your confirm button
          //   console.log("orderDetails: ", orderDetails)
          //   // capture the transaction funds
          //   return actions.order.capture().then(function(details) {
          //     console.log("onApprove: ", data)
          //     console.log("action.order.capture details", details)
          //     alert(
          //       "Transaction completed by " + details.payer.name.given_name
          //     )
          //     const paymentDetails = {
          //       captureDetails: details,
          //       orderDetails: details,
          //       onApproveData: data
          //     }
          //     vue.$emit("payment-complete", paymentDetails)
          //   })
          // })
        }
      }).render("#paypal-button-container"); // add event listener to when the debit/credit card button is clicked?
      // need to make sure to clear this listener as well
    }
  }
});